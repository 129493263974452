// Override default variables before the import
$body-bg: #fff;
$main-color: #000;
$effects-color: #dc8cdb;
// Import Bootstrap and its default variables
// eslint-disable-next-line
@import '~bootstrap/scss/bootstrap.scss';
@import './assets/css/templatemo-eduwell-style.css';

p {
    font-size: 16px;
}

p.copyright {
    color: $main-color;
    text-align: center;
    a {
        color: $effects-color;
    }
}

.main-action-button {
    font-size: 13px;
    color: #fff;
    background: rgb(219, 138, 222);
    background: linear-gradient(-145deg, rgb(219, 138, 222) 0%, rgb(246, 191, 159) 100%);
    padding: 12px 30px;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s;
}

section.get-info {
    .info-item {
        padding: 10px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        p:last-of-type {
            margin-bottom: 0px;
        }

        img {
            max-width: 70%;
        }

        h4 {
            margin-right: 15px;
        }
        pre {
            background-color: aliceblue;
            display: block;
        }
        &.selected-answer, &.selected-answer:hover {
            box-shadow: 0px 0px 15px rgb(219, 138, 222 );
        }
        &.correct-answer, &.correct-answer:hover {
            box-shadow: 0px 0px 15px rgb(165, 222, 138);
        }
        &:hover {
            box-shadow: 0px 0px 15px rgb(168, 168, 170);
        }

    }
}

.question-action {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    button {
        margin-right: 20px
    }
}

.section-heading.review {
    h4 {
        text-transform: none;
    }
}

@media (max-width: 992px) {
    section.get-info .info-item {
        margin-top: 20px;
    }
}