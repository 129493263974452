/*

TemplateMo 573 EduWell

https://templatemo.com/tm-573-eduwell

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800,900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 25px;
  color: #4a4a4a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: 'Open Sans', sans-serif;
}

::selection {
  background: #dc8cdb;
  color: #fff;
}

::-moz-selection {
  background: #dc8cdb;
  color: #fff;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.main-button-gradient a {
  font-size: 13px;
  color: #fff;
  background: rgb(219,138,222);
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%);
  padding: 12px 30px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
}

.main-button-gradient a:hover {
  background: rgb(219,138,222);
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%);
  opacity: 0.9;
}

.white-button a {
  font-size: 13px;
  color: #dc8cdb;
  background-color: #fff;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
}

.white-button a:hover {
  opacity: 0.9;
}

.text-button a {
  font-size: 14px;
  font-weight: 500;
  color: #dc8cdb;
  text-decoration: underline !important;
  transition: all .3s;
}

.text-button a:hover {
  opacity: 0.75;
}

section {
  margin-top: 140px;
}

#contact-section {
  padding-top: 20px;
}

.section-heading {
  position: relative;
  z-index: 2;
  padding-top: 20px;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 60px;
}

.section-heading h6 {
  font-size: 13px;
  text-transform: uppercase;
  color: #7a7a7a;
  font-weight: 600;
}

.section-heading h4 {
  margin-top: 10px;
  line-height: 40px;
  font-size: 28px;
  font-weight: 900;
  text-transform: capitalize;
  color: #111;
}

.section-heading h4 em {
  font-style: normal;
  color: #dc8cdb;
}

.section-heading p {
  margin-top: 30px;
}


/* 
---------------------------------------------
header
--------------------------------------------- 
*/
.background-header {
  background: rgb(219,138,222) !important;
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%) !important;
  height: 80px!important;
  position: fixed!important;
  top: 0!important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
}

.header-area {
  background-image: url(../images/header-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  height: 110px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  line-height: 100px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.background-header .main-nav .logo {
  line-height: 75px;
}

.background-header .nav {
  margin-top: 20px !important;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 35px;
  margin-right: 0px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 30px;
  padding-right: 30px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #fff!important;
  opacity: 0.75;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #fff!important;
  opacity: 1;
}

.header-area .main-nav .nav li.has-sub {
  position: relative;
  padding-right: 15px;
}

.header-area .main-nav .nav li.has-sub:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 12px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu {
  position: absolute;
  width: 140px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 40px;
  border-radius: 5px;
  opacity: 0;
  transition: all .3s;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a!important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.has-sub ul li a:hover {
  background: #fff;
  color: #dc8cdb!important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.has-sub ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.has-sub:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #fff;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area .nav {
  margin-top: 30px;
}

.header-area.header-sticky .nav li a.active {
  color: #dc8cdb;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 7px;
    padding-right: 7px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #dc8cdb!important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #dc8cdb!important;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
    transition: all 0s;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    height: auto;
  }
  .header-area .main-nav .nav li.has-sub:after {
    color: #3B566E;
    right: 30px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}


/* 
---------------------------------------------
banner
--------------------------------------------- 
*/

section.main-banner {
  margin-top: 0px;
}

.main-banner {
  padding-top: 180px;
}

.main-banner .right-image {
  margin-left: 120px;
  position: relative;
  z-index: 2;
}

.main-banner .right-image img {
  position: relative;
  z-index: 2;
}

.main-banner .right-image::after {
  position: absolute;
  background-image: url(../images/banner-right-dec.png);
  width: 378px;
  height: 378px;
  content: '';
  right: -25%;
  z-index: 1;
}

.main-banner .header-text::after {
  position: absolute;
  background-image: url(../images/banner-left-dec.png);
  width: 214px;
  height: 429px;
  content: '';
  left: 0;
  bottom: -240px;
  z-index: 1;
}

.main-banner .header-text h6 {
  position: relative;
  z-index: 2;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  color: #777777;;
}

.main-banner .header-text h2 {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  font-size: 64px;
  color: #111111;
  font-weight: 900;
  line-height: 74px;
  margin-bottom: 40px;
}

.main-banner .header-text h2 em {
  font-style: normal;
  color: #dc8cdb;
}

.main-banner .main-button-gradient {
  position: relative;
  z-index: 2;
}


/*
---------------------------------------------
services
---------------------------------------------
*/ 

.services .item {
  border-radius: 15px;
  text-align: center;
  padding: 50px 30px;
  margin: 15px;
  transition: all .3s;
}

.services .item:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
}

.services .item .icon {
  max-width: 75px;
  margin: 0 auto;
  min-height: 80px;
  line-height: 80px;
  vertical-align: middle;
}

.services .item h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 800;
}

.services .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.services .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0px 4px;
}

.services .owl-dots .active {
  background-color: #dc8cdb;
}


/*
---------------------------------------------
our courses
---------------------------------------------
*/

section.our-courses {
  position: relative;
}

section.our-courses .section-heading p {
  padding: 0px 60px;
}

section.our-courses:before {
  position: absolute;
  content: '';
  background-image: url(../images/our-courses-left-dec.png);
  left: 45px;
  top: -140px;
  width: 291px;
  height: 231px;
  z-index: 1;
}

section.our-courses:after {
  position: absolute;
  content: '';
  background-image: url(../images/our-courses-right-dec.png);
  right: 45px;
  top: -140px;
  width: 291px;
  height: 231px;
  z-index: 1;
}

section.our-courses .naccs {
  position: relative;
}

section.our-courses .naccs .menu div {
  padding: 15px 30px;
  text-align: center;
  border-radius: 5px !important;
  margin-bottom: 40px;
  font-size: 20px;
  color: #111;
  background-color: #fff;
  cursor: pointer;
  vertical-align: middle;
  font-weight: 900;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 3px solid rgba(219,138,222,0.5);
}

section.our-courses .naccs .menu div.active {
  color: #dc8cdb;
  border: 3px solid rgba(219,138,222,1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

section.our-courses ul.nacc {
  margin-left: 100px !important;
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

section.our-courses ul.nacc li {
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  transform: translateX(50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding-right: 50px;
}

section.our-courses ul.nacc li .left-image {
  position: absolute;
  margin-right: 50px;
  top: 50%;
  transform: translateY(-50%);
}

section.our-courses ul.nacc li .left-image img {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

section.our-courses ul.nacc li .left-image .price h6 {
  position: absolute;
  background: rgb(219,138,222);
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%);
  color: #fff;
  top: 20px;
  left: 20px;
  font-size: 20px;
  font-weight: 700;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
}

section.our-courses ul.nacc li .right-content {
  padding: 40px 0px;
  margin-left: 350px;
}

section.our-courses ul.nacc li .right-content h4 {
  font-size: 20px;
  color: #111;
  margin-bottom: 25px;
  font-weight: 800;
}

section.our-courses ul.nacc li .right-content ul {
  margin-top: 20px;
  margin-bottom: 20px;
}

section.our-courses ul.nacc li .right-content span {
  font-size: 14px;
  font-weight: 600;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 2px solid #111;
}

section.our-courses ul.nacc li .right-content .last-span {
  margin-right: 0px;
  padding-right: 0px;
  border-right: none;
}

section.our-courses ul.nacc li .right-content p {
  margin-bottom: 20px;
}

section.our-courses ul.nacc li .right-content .text-button {
  margin-top: 20px;
}

section.our-courses ul.nacc li.active {
  position: relative;
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}

section.our-courses ul.nacc li p {
  margin: 0;
}


/*
---------------------------------------------
cta
---------------------------------------------
*/

section.simple-cta {
  background-image: url(../images/cta-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding: 180px 0px 180px 0px;
}

section.simple-cta .left-image {
  margin-right: 45px;
}

section.simple-cta h6 {
  font-size: 17px;
  color: #fff;
}

section.simple-cta h4 {
  font-size: 62px;
  font-weight: 900;
  color: #fff;
  line-height: 74px;
  margin-top: 20px;
}

section.simple-cta p {
  color: #fff;
  margin-top: 30px;
  margin-bottom: 40px;
}


/* 
---------------------------------------------
testimonials
--------------------------------------------- 
*/

section.testimonials {
  position: relative;
}

section.testimonials:before {
  position: absolute;
  content: '';
  background-image: url(../images/testimonials-left-dec.png);
  left: 0px;
  top: -140px;
  width: 593px;
  height: 352px;
  z-index: 1;
}

section.testimonials:after {
  position: absolute;
  content: '';
  background-image: url(../images/testimonials-right-dec.png);
  right: 0px;
  bottom: -140px;
  width: 632px;
  height: 352px;
  z-index: 1;
}

.testimonials .item {
  border-radius: 15px;
  padding: 30px;
  background-color: #fff;
  margin: 15px;
  transition: all .3s;
  position: relative;
  z-index: 2;
}

.testimonials .item:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
}

.testimonials .item p {
  font-size: 16px;
  font-style: italic;
}

.testimonials .item h4 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 900;
  position: relative;
}

.testimonials .item span {
  font-size: 13px;
  font-weight: 700;
  color: #dc8cdb;
}

.testimonials .item img {
  max-width: 44px;
  z-index: 2;
  position: absolute;
  right: 30px;
  bottom: 30px
}

.testimonials .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.testimonials .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0px 4px;
}

.testimonials .owl-dots .active {
  background-color: #dc8cdb;
}



/* 
---------------------------------------------
contact us
--------------------------------------------- 
*/

section.contact-us {
  background-image: url(../images/footer-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  position: relative;
}

section.contact-us:after {
  position: absolute;
  content: '';
  background-image: url(../images/footer-left-dec.png);
  left: 0px;
  top: 60px;
  width: 267px;
  height: 396px;
  z-index: 1;
}

section.contact-us .contact-info {
  background-color: #fff;
  margin-top: -30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  z-index: 5;
  position: relative;
  padding: 20px;
}

section.contact-us .contact-info .icon i {
  float: left;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background: rgb(219,138,222);
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%);
  color: #fff;
}

section.contact-us .contact-info h4 {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
}

section.contact-us .contact-info span {
  font-size: 15px;
  color: #dc8cdb;
  font-weight: 700;
}

section.contact-us .section-heading {
  text-align: left;
  margin-bottom: 40px;
}

section.contact-us #contact {
  margin-left: -100px;
  position: relative;
  z-index: 5;
  background-color: #fff;
  margin-top: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 40px;
}

section.contact-us #contact input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: #f4f7fb;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  color: #7a7a7a;
  padding: 0px 15px;
  margin-bottom: 30px;
}

section.contact-us #contact textarea {
  width: 100%;
  min-height: 140px;
  max-height: 180px;
  border-radius: 5px;
  background-color: #f4f7fb;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  color: #7a7a7a;
  padding: 15px;
  margin-bottom: 30px;
}

section.contact-us #contact button {
  float: right;
  font-size: 13px;
  color: #fff;
  background: rgb(219,138,222);
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%);
  padding: 12px 30px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
  transition: all .3s;
  border: none;
  outline: none;
}

section.contact-us #contact button:hover {
  opacity: 0.9;
}

section.contact-us ul.social-icons li {
  display: inline-block;
  margin: 0px 5px;
}

section.contact-us ul.social-icons {
  text-align: center;
  margin-top: 70px;
}

section.contact-us ul.social-icons li a {
  width: 44px;
  height: 44px;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  background-color: #fff;
  color: #dc8cdb;
  border-radius: 50%;
  transition: all .5s;
}

section.contact-us ul.social-icons li a:hover {
  background-color: #dc8cdb;
  color: #fff;
}

p.copyright {
  text-align: center;
  color: #fff;
  margin: 40px 0px;
}

p.copyright a {
  font-weight: 700;
  color: #fff;
}

/*
---------------------------------------------
heading page
---------------------------------------------
*/

section.page-heading {
  margin-top: 190px;
  text-align: center;
}

section.page-heading .header-text {
  border-bottom: 3px solid #eee;
  padding-bottom: 80px;
}

section.page-heading .header-text h4 {
  font-size: 17px;
  text-transform: capitalize;
  color: #7a7a7a;
}

section.page-heading .header-text h1 {
  font-size: 62px;
  font-weight: 900;
  color: #111;
  margin-top: 10px;
}


/*
---------------------------------------------
about us page
---------------------------------------------
*/

section.get-info .left-image {
  margin-right: 30px;
}

section.get-info .section-heading {
  text-align: left;
}

section.get-info .info-item {
  border-radius: 15px;
  text-align: left;
  padding: 60px 30px 30px 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  position: relative;
}

section.get-info .info-item .icon {
  position: absolute;
  left: 30px;
  top: -30px;
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
}

section.get-info .info-item .icon img {
  max-width: 60%;
}

section.get-info .info-item h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 800;
}

section.our-team {
  position: relative;
}

section.our-team .section-heading p {
  padding: 0px 60px;
}

section.our-team:before {
  position: absolute;
  content: '';
  background-image: url(../images/our-courses-left-dec.png);
  left: 45px;
  top: -140px;
  width: 291px;
  height: 231px;
  z-index: 1;
}

section.our-team:after {
  position: absolute;
  content: '';
  background-image: url(../images/our-courses-right-dec.png);
  right: 45px;
  top: -140px;
  width: 291px;
  height: 231px;
  z-index: 1;
}

section.our-team .naccs {
  position: relative;
  text-align: center;
}

section.our-team .naccs .menu {
  position: relative;
  display: inline-block;
}

section.our-team .naccs .menu div {
  margin: 0px 15px;
  text-align: center;
  display: inline-block;
  width: 15%;
  text-align: center;
  font-size: 20px;
  color: #111;
  background-color: #fff;
  cursor: pointer;
  vertical-align: middle;
  font-weight: 900;
}

section.our-team .naccs .menu div img {
  border-radius: 50%;
  max-width: 120px;
}

section.our-team .naccs .menu div h4 {
  font-size: 15px;
  font-weight: 900;
  margin-top: 15px;
  margin-bottom: 5px;
}

section.our-team .naccs .menu div span {
  font-size: 12px;
  text-transform: uppercase;
  color: #dc8cdb;
  font-weight: 700;
  display: block;
}

section.our-team .naccs .menu div.active h4 {
  color: #dc8cdb;
}

section.our-team ul.nacc {
  margin-top: 40px !important;
  position: relative;
  min-height: 100%;
  height: 100% !important;
  list-style: none;
  margin: 0;
  padding: 0;
}

section.our-team ul.nacc li {
  z-index: -1;
  opacity: 0;
  transform: translateX(50px);
  background-color: #fff;
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 40px;
}

section.our-team ul.nacc li.active {
  position: relative !important;
  overflow: hidden;
}

section.our-team ul.nacc li .right-image {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

section.our-team ul.nacc li .right-image img {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

section.our-team ul.nacc li .left-content {
  text-align: left;
  margin-right: 380px;
}

section.our-team ul.nacc li .left-content h4 {
  font-size: 20px;
  color: #111;
  margin-bottom: 25px;
  font-weight: 800;
}

section.our-team ul.nacc li .left-content span a {
  font-size: 14px;
  font-weight: 600;
  margin-right: 30px;
  padding-right: 30px;
  color: #111;
  border-right: 2px solid #111;
  transition: all .3s;
}

section.our-team ul.nacc li .left-content span a:hover {
  color: #dc8cdb;
}

section.our-team ul.nacc li .left-content .last-span a {
  margin-right: 0px;
  padding-right: 0px;
  border-right: none;
}

section.our-team ul.nacc li .left-content p {
  margin-bottom: 30px;
}

section.our-team ul.nacc li .left-content .text-button {
  margin-top: 30px;
}

section.our-team ul.nacc li.active {
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}

section.our-team ul.nacc li p {
  margin: 0;
}

section.our-office .left-info {
  margin-right: 0px;
  position: relative;
  z-index: 5;
  background-color: #fff;
  margin-top: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 40px;
}

section.our-office .left-info p {
  margin-bottom: 40px;
}

section.our-office .left-info ul li {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  font-style: italic;
  margin-bottom: 20px;
}

section.our-office .left-info ul li:last-child {
  margin-bottom: 0px;
}

section.our-office .left-info .main-button-gradient {
  margin-top: 40px;
}

section.our-office #video {
  margin-left: -70px;
}

section.our-office #video .thumb {
  position: relative;
}

section.our-office #video .thumb .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
}

section.our-office #video .thumb .play-button a {
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  transform: translate(-40px, -40px);
  color: #fff;
  font-size: 22px;
  background: rgb(219,138,222);
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%);
}

section.our-office #video img {
  border-radius: 20px;
}



/*
---------------------------------------------
our services page
---------------------------------------------
*/

section.featured-product {
  position: relative;
}

section.featured-product:before {
  position: absolute;
  content: '';
  background-image: url(../images/testimonials-left-dec.png);
  left: 0px;
  top: -140px;
  width: 593px;
  height: 352px;
  z-index: 1;
}

section.featured-product:after {
  position: absolute;
  content: '';
  background-image: url(../images/testimonials-right-dec.png);
  right: 0px;
  bottom: -140px;
  width: 632px;
  height: 352px;
  z-index: 1;
}

section.featured-product .info-item {
  border-radius: 15px;
  text-align: left;
  padding: 60px 30px 30px 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  position: relative;
  margin-bottom: 90px;
}

section.featured-product .last-info {
  margin-bottom: 0px;
}

section.featured-product .left-info .info-item .icon {
  right: 30px;
  top: -30px;
}

section.featured-product .left-info .info-item {
  text-align: right;
}

section.featured-product .right-info .info-item .icon {
  left: 30px;
  top: -30px;
}

section.featured-product .info-item .icon {
  position: absolute;
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
}

section.featured-product .info-item .icon img {
  max-width: 60%;
}

section.featured-product .info-item h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 800;
}

section.featured-product .product-image {
  margin: 0px 60px;
}


/*
---------------------------------------------
contact us page
---------------------------------------------
*/

section.more-info .section-heading {
  margin-bottom: 40px;
  text-align: left;
}

section.more-info p {
  margin-bottom: 40px;
}

section.more-info ul li {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  font-style: italic;
  margin-bottom: 20px;
}

section.more-info ul li:last-child {
  margin-bottom: 0px;
}

section.more-info .count-area-content {
  margin-bottom: 20px;
  margin-top: 20px;
}

section.more-info .count-area-content .count-digit {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-40px);
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 28px;
  color: #dc8cdb;
  font-weight: 900;
  margin-bottom: -40px;
  position: relative;
  z-index: 2;
}

section.more-info .count-area-content .count-title {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: #111;
  padding: 50px 30px 30px 30px;
  position: relative;
  z-index: 1;
}


/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/


body {
  overflow-x: hidden;
}

@media (max-width: 1330px) {
  .services .owl-nav .owl-next{
    right: -30px;
  }
  .services .owl-nav .owl-prev{
    left: -25px;
  }
  .our-courses .owl-nav .owl-next{
    right: -30px;
  }
  .our-courses .owl-nav .owl-prev{
    left: -25px;
  }
}

@media (max-width: 1200px) {
  .main-banner .right-image {
    margin-left: 60px;
  }
  .main-banner .header-text h2 {
    font-size: 62px;
  }
  .header-area {
    background: rgb(219,138,222) !important;
    background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%) !important;
  }
  .services .owl-nav .owl-next{
    right: -70px;
  }
  .services .owl-nav .owl-prev{
    left: -65px;
  }
  .our-courses .owl-nav .owl-next{
    right: -70px;
  }
  .our-courses .owl-nav .owl-prev{
    left: -65px;
  }
}

@media (max-width: 1085px) {
  .services .owl-nav .owl-next{
    right: -30px;
  }
  .services .owl-nav .owl-prev{
    left: -25px;
  }
  .our-courses .owl-nav .owl-next{
    right: -30px;
  }
  .our-courses .owl-nav .owl-prev{
    left: -25px;
  }
}

@media (max-width: 1005px) {
  .services .owl-nav .owl-next{
    display: none;
  }
  .services .owl-nav .owl-prev{
    display: none;
  }
  .our-courses .owl-nav .owl-next{
    display: none;
  }
  .our-courses .owl-nav .owl-prev{
    display: none;
  }
}

@media (max-width: 992px) {

  .main-banner .header-text {
    text-align: center;
    margin-bottom: 60px;
  }
  .main-banner .right-image {
    margin-left: 0px;
  }
  section.our-courses ul.nacc {
    margin-left: 0px !important;
  }
  section.simple-cta {
    margin-top: 0px;
  }
  section.simple-cta .left-image {
    margin-right: 0px;
    margin-bottom: 45px;
  }
  section.contact-us .contact-info {
    margin-top: 15px;
  }
  section.contact-us #contact {
    margin-left: 0px;
  }
  section.contact-us #contact button {
    float: none;
  }
  section.get-info .left-image {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  section.get-info .info-item {
    margin-top: 60px;
  }
  section.our-office #video {
    margin-left: 0px;
    margin-top: 30px;
  }
  section.featured-product .left-info .info-item,
  section.featured-product .right-info .info-item {
    text-align: center;
    background-color: #fff;
    position: relative;
    z-index: 2;
  }
  section.featured-product .left-info .info-item .icon {
    right: 50%;
    transform: translateX(40px);
  }
  section.featured-product .right-info .info-item .icon {
    left: 50%;
    transform: translateX(-40px);
  }
  section.featured-product .product-image {
    margin: 60px
  }
  section.our-team ul.nacc li .left-content span a,
  section.our-courses ul.nacc li .right-content span {
    margin-right: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    display: none;
  }
  section.our-courses ul.nacc li .left-image,
  section.our-team ul.nacc li .right-image {
    position: relative;
    margin-right: 0px;
    margin-left: 0px;
    transform: translateY(-0%);
  }
  section.our-courses ul.nacc li .left-image img,
  section.our-team ul.nacc li .right-image img {
    border-radius: 15px;
  }
  section.our-courses ul.nacc li,
  section.our-team ul.nacc li {
    padding: 0px;
  }
  section.our-courses ul.nacc li .right-content {
    margin-left: 0px;
    padding: 30px;
  }
  section.our-team ul.nacc li .left-content {
    margin-right: 0px;
    padding: 30px;
  }
  section.our-team .naccs .menu div {
    width: 14%;
    margin: 0px 5px;
  }
  section.our-team .naccs .menu div.active img {
    border: 3px solid #dc8cdb;
  }
  section.our-team .naccs .menu div h4,
  section.our-team .naccs .menu div span {
    display: none;
  }
  section.more-info .count-area-content .count-title {
    font-size: 22px;
  }
}